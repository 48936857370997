<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Services</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Services</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card" id="department-add">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <form @submit.prevent="handleSubmission()" method="POST" novalidate>
          <div class="card-header">
            <h3 class="card-title" v-if="!is_edit">Create</h3>
            <h3 class="card-title" v-if="is_edit">Edit</h3>
            <div class="card-tools">
              <router-link class="btn btn-info" :to="{name: 'Services'}"><i class="fas fa-list"></i> List
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group required">
              <label for="name" class="control-label">Name</label>
              <input type="text" id="name" name="name"
                     v-bind:class="{'has-error' : errors.has('name')}"
                     v-validate="'required'"
                     v-model="inputs.name" class="form-control name"
                     placeholder="Name">
              <div class="help text-danger" v-show="errors.has('name')">
                {{ errors.first('name') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="display_name" class="control-label">Display Name</label>
              <input type="text" id="display_name" name="display_name"
                     v-bind:class="{'has-error' : errors.has('display_name')}"
                     v-validate="'required'"
                     v-model="inputs.display_name" class="form-control name"
                     placeholder="Display name">
              <div class="help text-danger" v-show="errors.has('display_name')">
                {{ errors.first('display_name') }}
              </div>
            </div>
            <div class="form-group required">
              <label for="service_logo" class="control-label">Service logo</label>
              <div class="row">
                <div class="col-4">
                  <input type="file" name="service_logo" class="form-control-file" id="service_logo"
                         v-bind:class="{'has-error' : errors.has('service_logo')}"
                         @change="selectFile">
                  <div class="help text-danger" v-show="errors.has('service_logo')">
                    {{ errors.first('service_logo') }}
                  </div>
                </div>
                <div class="col-8">
                  <img alt="preview image" v-if="temp_logo" v-bind:src="temp_logo" width="100" height="100"
                       class="img-thumbnail"/>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="is_wallet" class="control-label">Is Wallet?</label>
              <br>
              <el-switch
                v-bind:class="{'has-error' : errors.has('is_wallet')}"
                v-model="inputs.is_wallet" id="is_wallet" name="wallet"
                :active-value=1 :inactive-value=0 :width=50>
              </el-switch>
              <div class="help text-danger" v-show="errors.has('is_wallet')">
                {{ errors.first('is_wallet') }}
              </div>
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
            <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>

import {ServicesAPI} from "@/services/api";

export default {
  name: 'ServiceForm',
  metaInfo: {
    title: 'Service Form | Dotlines'
  },
  data: () => ({
    inputs: {},
    temp_logo: '',
    is_loading: false,
    is_edit: false,
    params: {},
  }),
  methods: {
    handleSubmission() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.is_edit) {
            ServicesAPI.update(this.params.id, this.inputs).then(response => {
              this.$router.push({name: 'Services'});
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error.data);
            });
          } else {
            ServicesAPI.store(this.inputs).then(response => {
              this.$router.push({name: 'Services'});
              this.$toaster.success(response.message)
            }).catch(error => {
              if(error.data.status === 500) {
                this.$toaster.error(error.data.message)
              }
              this.$setErrorsFromResponse(error.data);
            });
          }
        }
      });
    },
    async serviceShow(id) {
      this.asset_get_url = process.env.VUE_APP_ASSET_UPLOAD_URL;
      await ServicesAPI.show(id).then(service => {
        this.inputs = service;
        this.temp_logo = this.asset_get_url + '/' + this.inputs.service_logo;
        this.$delete(this.inputs, 'service_logo');
      });
    },
    selectFile: function (event) {
      // `files` is always an array because the file input may be in multiple mode
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.inputs.service_logo = reader.result;
        this.temp_logo = reader.result;
      };

    },
    async initialLoad() {
      this.is_loading = true;
      this.params = this.$route.params;
      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        await this.serviceShow(this.params.id);
      }
      this.is_loading = false;
    }
  },
  mounted() {
    this.initialLoad()
  }
}
</script>
